import { FC, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";

import Carousel from "components/carousel/Carousel";
import useWindowSize from "hooks/useWindowSize";
import { Review } from "common/types/review";
import ReviewCard from "./ReviewCard";
import Link from "next/link";
import CategorySectionHeader from "components/CategorySectionHeader";
import { navigationRoutes } from "common/constants/navigation-routes";
import { Paragraph } from "components/Typography";
import BazarImage from "components/BazarImage";
import { colors } from "common";
import { useRouter } from "next/router";

type Props = {
  reviews?: Review[];
  hasBanner?: boolean;
  image?: string;
  linkInNewTab?: boolean; // we dont want user to navigate out
  headerTitle?: string;
  seeMoreLink?: string;
};

const ReviewCarousel: FC<Props> = ({
  reviews = [],
  hasBanner = false,
  image = "/assets/images/landing/pc_worth_20000_builds_1000_reviews_100_customer_satisfaction.png",
  linkInNewTab = false,
  headerTitle = "Customer Reviews",
  seeMoreLink = navigationRoutes.googleReviews,
}) => {
  const [visibleSlides, setVisibleSlides] = useState(4);
  const width = useWindowSize();

  const { pathname } = useRouter();

  const isCheckoutPage = useMemo(() => {
    return pathname === navigationRoutes.checkout;
  }, [pathname]);

  useEffect(() => {
    if (width < 500) setVisibleSlides(1);
    else if (width < 650) setVisibleSlides(2);
    else if (width < 950) setVisibleSlides(3);
    //TODO: check what is this
    // else if (isForDialog) setVisibleSlides(3);
    else setVisibleSlides(4);
  }, [width]);

  return (
    <Box flex="1 1 auto" minWidth="0px" mt={4}>
      {hasBanner && (
        <Box mb={3}>
          <BazarImage
            alt="customer satisfaction reviews"
            src={image}
            sx={{ width: "100%", mt: 2 }}
          />

          {isCheckoutPage ? (
            <Paragraph sx={{ cursor: "pointer" }} mt={1} color={colors.link}>
              https://www.facebook.com/PC.WORTH/reviews
            </Paragraph>
          ) : (
            <Link passHref href="https://www.facebook.com/PC.WORTH/reviews">
              <a
                target={linkInNewTab ? "_blank" : ""}
                rel={linkInNewTab ? "noopener noreferrer" : ""}
              >
                <Paragraph
                  sx={{ cursor: "pointer" }}
                  mt={1}
                  color={colors.link}
                >
                  https://www.facebook.com/PC.WORTH/reviews
                </Paragraph>
              </a>
            </Link>
          )}
        </Box>
      )}
      <CategorySectionHeader
        title={headerTitle}
        seeMoreLinkTxt="See All"
        seeMoreLink={seeMoreLink}
      />

      {reviews?.length > 0 ? (
        <Carousel
          spacing={"-0.5rem"}
          infinite={true}
          autoPlay
          showArrow={false}
          totalSlides={reviews.length || 1}
          visibleSlides={visibleSlides}
        >
          {reviews.map((packageItem, index) => (
            <Box key={index} sx={{ p: 1 }}>
              <ReviewCard clickable={!isCheckoutPage} review={packageItem} />
            </Box>
          ))}
        </Carousel>
      ) : null}
    </Box>
  );
};

export default ReviewCarousel;
