import { Box, Button, styled } from "@mui/material";
import {
  formatCurrency,
  formatCurrencySRP,
  productDetailsPageUrl,
} from "common";
import BazarCard from "components/BazarCard";
import LazyImage from "components/LazyImage";
import { H3, Span } from "components/Typography";
import { CartItem } from "modules/cart/types";
import { Product } from "modules/products/types";
import Link from "next/link";
import { FlexBox } from "../flex-box";
import React, { CSSProperties, useCallback, useMemo } from "react";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import { useCart } from "hooks/use-cart";
import { useRouter } from "next/router";
import { usePcwUserCart } from "hooks/use-pcw-cart";
import { useLoginStatus } from "hooks/useLoginStatus";
import { useDialogueShow } from "hooks/useDialogue";

const StyledBazarCard = styled(BazarCard)(() => ({
  height: "100%",
  margin: "auto",
  display: "flex",
  overflow: "hidden",
  borderRadius: "8px",
  position: "relative",
  flexDirection: "column",
  justifyContent: "space-between",
  transition: "all 250ms ease-in-out",
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
  textAlign: "center",
  position: "relative",
  display: "inline-block",
  [theme.breakpoints.down("sm")]: { display: "block" },
}));

const ContentWrapper = styled(Box)(() => ({
  padding: "1rem",
  "& .title, & .categories": {
    overflow: "hidden",
    whiteSpace: "normal",
    textOverflow: "ellipsis",
  },
}));

// ========================================================
type ProductCard1Props = {
  className?: string;
  style?: CSSProperties;
  hoverEffect?: boolean;
  slug?: string;
  category?: string;
  category_id?: number;
  product_name?: string;
  img_thumbnail?: string;
  mfr_logo?: string;
  isMobile?: boolean;
  amount?: number;
  productData: Product;
};
// ========================================================

// TODO: Just create a common prop maybe 'Product'
const ProductCard1: React.FC<ProductCard1Props> = ({
  hoverEffect,
  productData,
}) => {
  const { push } = useRouter();
  const { productQtyInCartViaSlug } = useCart();
  const { isUserLogin } = useLoginStatus();
  const { userCartLoading, addUserCartItem } = usePcwUserCart();
  const { showLoginFormOpen } = useDialogueShow();

  const isLogin = isUserLogin;

  const onClickAddCart = useCallback(() => {
    const {
      slug,
      product_name,
      img_thumbnail,
      category_id,
      category,
      amount,
      stocks_left,
      with_bundle,
      compatible_mobos,
    } = productData;

    const cartItem: CartItem = {
      product_slug: slug,
      product_name,
      img_thumbnail,
      category_id,
      category,
      amount,
      quantity: 1,
      with_bundle: with_bundle || 0,
      compatible_mobos: compatible_mobos || [],
      stocks_left: stocks_left || 999,
    };

    if (isLogin) {
      addUserCartItem(slug, 1, cartItem);
    } else {
      showLoginFormOpen();
    }
  }, [addUserCartItem, isLogin, productData, showLoginFormOpen]);

  const onViewDetails = () => {
    push(productDetailsPageUrl(productData?.slug, productData?.product_uuid));
  };

  const addToCartDisabled = useMemo(() => {
    const remainingStocks = productData?.stocks_left;
    const qtyInCart = productQtyInCartViaSlug(productData?.slug) || 0;
    if (qtyInCart >= remainingStocks) {
      return true;
    }
    return false;
  }, [productData?.slug, productData?.stocks_left, productQtyInCartViaSlug]);

  return (
    <StyledBazarCard hoverEffect={hoverEffect}>
      <ImageWrapper>
        <Link
          href={productDetailsPageUrl(
            productData?.slug,
            productData?.product_uuid
          )}
        >
          <a>
            <Box
              sx={{
                marginTop: "16px",
                paddingX: "16px",
                alignItems: "center",
              }}
            >
              <LazyImage
                src={
                  productData?.img_thumbnail ||
                  "/assets/images/sad_red_hair.png"
                }
                width={0}
                height={0}
                layout="responsive"
                objectFit="contain"
                alt={productData?.product_name}
              />
            </Box>
          </a>
        </Link>
      </ImageWrapper>

      <ContentWrapper>
        <Link
          href={productDetailsPageUrl(
            productData?.slug,
            productData?.product_uuid
          )}
        >
          <a>
            <FlexBox>
              <Box flex="1 1 0" minWidth="0px" mr={1}>
                <H3
                  mb={1}
                  title={productData?.product_name}
                  fontSize="14px"
                  fontWeight="600"
                  className="title"
                  color="text.secondary"
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                  }}
                >
                  {productData?.product_name}
                </H3>

                <FlexBox flexDirection="column" mt={0.5}>
                  <Box fontSize="24px" fontWeight="600" color="primary.main">
                    {formatCurrency(productData?.amount)}
                  </Box>
                  <Span
                    fontSize="16px"
                    color="grey.600"
                    sx={{ textDecoration: "line-through" }}
                  >
                    {formatCurrencySRP(productData?.amount)}
                  </Span>
                </FlexBox>
              </Box>
            </FlexBox>
          </a>
        </Link>
        <FlexBox
          className="add-cart"
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
        >
          <LazyImage
            src={productData?.mfr_logo}
            width={40}
            height={20}
            layout="fixed"
            objectFit="contain"
          />

          <Box>
            <Button
              color="secondary"
              size="small"
              variant="outlined"
              sx={{ p: 0.3 }}
              onClick={onViewDetails}
            >
              <FindInPageOutlinedIcon sx={{ fontSize: 20 }} />
            </Button>
            {!productData?.with_bundle && !addToCartDisabled ? (
              <Button
                size="small"
                color="secondary"
                variant="contained"
                onClick={onClickAddCart}
                disabled={addToCartDisabled || userCartLoading}
                sx={{ p: 0.3, marginLeft: 1 }}
              >
                <AddShoppingCartIcon sx={{ fontSize: 20 }} />
              </Button>
            ) : null}
          </Box>
        </FlexBox>
      </ContentWrapper>
    </StyledBazarCard>
  );
};

export default ProductCard1;
