import { FC } from "react";
import { Container } from "@mui/material";

import { _ } from "common";
import AllProductsSection from "./AllProductsSection";
import CategoriesSection from "./CategoriesSection";
import LatestBuildsSection from "./LatestBuildsSection";
import PCbuilderSection from "./PCbuilderSection";
import LatestProductsSection from "./LatestProductsSection";
import TarpaulinSection from "./TarpaulinSection";
import PackageList from "./PackageList";
import FlashDealSection from "./FlashDealSection";
import { pcwEvent } from "common/constants/pcw-event";
import ReviewCarousel from "components/reviews/ReviewCarousel";
import BestSellerSection from "./BestSellerSection";
import { PCWorthOverview } from "components/footer/PCWorthOverview";
import { RecentlyViewedProducts } from "./RecentlyViewedProducts";
import { fbReviews } from "common/constants/fb-reviews";
import { googleReviews } from "common/constants/google-reviews";
import { navigationRoutes } from "common/constants/navigation-routes";

type Props = {};

const Section6: FC<Props> = (props) => {
  return (
    <Container sx={{ mb: "3em" }}>
      <TarpaulinSection />
      {pcwEvent.isEventOnGoing ? null : <CategoriesSection />}
      <ReviewCarousel
        headerTitle="Facebook Reviews"
        reviews={fbReviews?.slice(0, 20)}
      />
      <ReviewCarousel
        headerTitle="Google Reviews"
        reviews={googleReviews?.slice(0, 20)}
        seeMoreLink={navigationRoutes.googleReviews}
      />
      <PCbuilderSection />
      <BestSellerSection isLarge={true} />
      <RecentlyViewedProducts />
      <PackageList />
      <FlashDealSection />
      <LatestBuildsSection />
      {pcwEvent.isEventOnGoing ? null : <LatestProductsSection />}
      {pcwEvent.isEventOnGoing ? null : <AllProductsSection />}
      <PCWorthOverview />
    </Container>
  );
};

export default Section6;
