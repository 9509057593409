import React, { FC, memo, useEffect } from "react";

// required swiper css imports
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/css/controller";
import "swiper/css";
import "swiper/css/navigation";
import CategorySectionHeader from "components/CategorySectionHeader";
import SwiperCarousel from "components/swiper/SwiperCarousel";
import modules from "modules";
import { useAppSelector } from "hooks/use-app-selector";
import ProductCard1 from "components/product-cards/ProductCard1";
import { Box } from "@mui/material";

const { selectors: recentlyViewedSelectors } = modules.recentlyViewed;

interface Props {}

export const RecentlyViewedProducts: FC<Props> = () => {
  const breakpoints = {
    0: { slidesPerView: 1.25 },
    600: { slidesPerView: 2.25 },
    900: { slidesPerView: 3.25 },
    1200: { slidesPerView: 5.25 },
    1536: { slidesPerView: 5.25 },
  };

  const recentlyViewed = useAppSelector(
    recentlyViewedSelectors?.selectRecentlyViewedItems
  );

  return recentlyViewed?.[0] ? (
    <Box mt={4}>
      <CategorySectionHeader title="Recently Viewed" />
      <SwiperCarousel
        id="recent-items"
        data={recentlyViewed}
        renderItem={(item) => <ProductCard1 hoverEffect productData={item} />}
        breakpoints={breakpoints}
      />
    </Box>
  ) : null;
};
