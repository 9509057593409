import { Box } from "@mui/material";
import CategorySectionHeader from "components/CategorySectionHeader";
import { FC, useEffect } from "react";
import modules from "modules";
import PackageCard from "components/product-cards/PackageCard";
import { useAppDispatch } from "hooks/use-app-dispatch";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/css/controller";
import "swiper/css";
import "swiper/css/navigation";
import { useAppSelector } from "hooks/use-app-selector";
import { navigationRoutes } from "common/constants/navigation-routes";
import SwiperCarousel from "components/swiper/SwiperCarousel";

const { actions: actionPcPackage, selectors: selectorPcPackage } =
  modules.pcPackage;

const PackageListSection: FC = () => {
  const dispatch = useAppDispatch();
  const pcPackageList = useAppSelector(selectorPcPackage.selectPcPackageList);

  const breakpoints = {
    0: { slidesPerView: 1.25 },
    600: { slidesPerView: 2.25 },
    900: { slidesPerView: 3.25 },
    1200: { slidesPerView: 3.25 },
    1536: { slidesPerView: 3.25 },
  }

  // TODO: Use on Skeleton
  const isPcPackageListLoading = useAppSelector(
    selectorPcPackage.selectPcPackageListLoading
  );

  const getPcPackageList = () => {
    dispatch(actionPcPackage.getPcPackageListThunk({}));
  };

  useEffect(() => {
    getPcPackageList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box flex="1 1 auto" minWidth="0px" mt={4}>
      {pcPackageList?.length > 0 ? (
        <CategorySectionHeader
          title="PC Package"
          seeMoreLinkTxt="See All"
          seeMoreLink={navigationRoutes.pcPackage}
        />
      ) : null}
      <SwiperCarousel
        id='pcPackage'
        data={pcPackageList}
        renderItem={(item) => <PackageCard pcPackageData={item} hoverEffect />}
        isLoading={isPcPackageListLoading}
        breakpoints={breakpoints}
      />
    </Box>
  );
};
export default PackageListSection;